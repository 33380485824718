import styled from "styled-components"
import { SectionVision } from "./SectionVision"
import { Topbar } from "./Topbar"
import { SectionDeepLearning } from "./SectionDeepLearning"
import { SectionCryptography } from "./SectionCryptography"
import { SectionContactUs } from "./SectionContactUs"
import { SectionProducts } from "./SectionProducts"
import { useEffect } from "react"
import { PanScroll } from "./components/PanScroll"


const DesktopDiv=styled.div`
    display: flex;
    flex-direction:column;
    align-items: center;
    position: relative;
`

const BgDiv=styled.div`
    background-image: url("res/bg.svg");
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: auto 70%;
    width: 100vw;
    height: 100vh;
    max-width: 1500px;
    position: fixed;
    opacity: .6;
`

export const Desktop = (params:{isMobile:boolean})=>{

    useEffect(()=>{
        document.body.onscroll=(evt:Event)=>{
            console.log(evt)
        }
    },[])



    return <DesktopDiv>
        <BgDiv/>
        <Topbar isMobile={params.isMobile}/>
        <PanScroll useBullets={!params.isMobile} simpleScroll={false/*params.isMobile*/} maxWidth="1280px" sections={[
            {
                section:<SectionVision/>,
                id:"vision"
            },
            {
                section:<SectionDeepLearning/>,
                id:"deepLearning"
            },
            {
                section:<SectionCryptography/>,
                id:"services"
            },
            {
                section:<SectionProducts/>,
                id:"products"
            },
            {
                section:<SectionContactUs/>,
                id:"contacts"
            },
        ]}/>
        
    </DesktopDiv>
}

//