import { useEffect, useRef } from "react"
import styled from "styled-components"
import { SignalHandler } from "./utils/Signal"
import GD from "./GD"

interface SectionDivParams{
    fitContent:Boolean,
    margin?:string
}

const SectionDiv = styled.div<SectionDivParams>`
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative;
    ${({ theme }) => {
        if(theme.theme!=="mobile"){
            return `
                margin-top: 120px;
            `
        }
    }};
   
`

const SectionTableDiv=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    ${({ theme }) => {
        if(theme.theme!=="mobile"){
            return `
                margin-top: 120px;
            `
        }
    }};
`

const SectionInnerDiv = styled.div`
    display: grid;
    margin: 0 30px 0 30px;
    width: 100%;
    

    ${({ theme }) => {
        if(theme.theme!=="mobile"){
            return `
                grid-template-columns: 50% 50%;
                gap:0;
            `
        }
    }};


    >div:first-child{
       
        text-transform: uppercase;
        font-weight: 500;
        
        display: flex;
        flex-direction: column;
        

        ${({ theme }) => {
            if(theme.theme!=="mobile"){
                return `
                    justify-self: flex-end;
                    text-align: right;
                    align-items: flex-end;
                    margin-right: 65px;
                    font-size: 68px;
                `
            }else{
                return `
                    font-size:32px;
                `
            }
        }};

    }
    >div>p{
       
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        
        text-transform:none;

    
        
        //letter-spacing: 1.1px;
        ${({ theme }) => {
            if(theme.theme!=="mobile"){
                return `
                    text-align: right;
                    line-height: 34px;
                `
            }else{
                return `
                    color:#8C8C8C;
                    text-transform:none;
                    font-size:16px;
                `
            }
        }};
    }
    >div:nth-child(2){
        
        display: flex;
        flex-direction: column;
        gap:35px;
        font-size: 20px;
        margin-top: 16px;
     
        ${({ theme }) => {
            if(theme.theme!=="mobile"){
                return `
                    margin-left: 65px;
                    line-height: 34px;
                `
            }else{
                return `
                    color:#8C8C8C;
                    font-size:16px;
                `
            }
        }};
    }
    >div>div[data-type="block"]>div:first-child{
        text-transform: uppercase;
        ${({ theme }) => {
            if(theme.theme==="mobile"){
                return `
                    color:#FFFFFF;
                `
            }
        }};
    }
`



export const Section=(params:{children:any,margin?:string,fitContent?:boolean,id:string,type?:"text"|"logos"})=>{
    if(!params.type || params.type==="text")
        return <SectionDiv margin={params.margin} fitContent={params.fitContent ?? false}>
            <SectionInnerDiv>
                {params.children}
            </SectionInnerDiv>
        </SectionDiv>

    return <SectionTableDiv>
        <div>{params.children}</div>
    </SectionTableDiv>
    
}