import { Lang } from "./Lang"
import { Section } from "./Section"

export const SectionDeepLearning = ()=>{
    return <Section id={"deepLearn"}>
            <div>
                {Lang.get("sectionDeepTitle1")}<br/>{Lang.get("sectionDeepTitle2")}
            </div>
            <div>
                {Lang.get("sectionDeepTxt")}
            </div>

    </Section>
}