import styled from "styled-components"
import { Lang } from "./Lang"
import { Section } from "./Section"

const ProdSectionDiv = styled.div`
    display: grid;
    justify-content: center;
   // margin-bottom: 200px;

    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                grid-template-columns:  repeat(1,300px);
                row-gap:40px;
            `
        }else{
            return `
                grid-template-columns:  repeat(3,300px);
                column-gap:90px;
            `
        }
    }};
`

interface ProdDivParams{
    icon:string
}
const ProdDiv = styled.div<ProdDivParams>`
    background-image: url(${(p:ProdDivParams)=>p.icon});
    background-position: center top;
    background-repeat: no-repeat;
    
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
 
    letter-spacing: 0.72px;

   
    transition: transform .2s ease;

    &[data-interactive="true"]{
         cursor: pointer;
    }
    &[data-interactive="true"]:hover{
        transform: scale(1.1,1.1);
    }

    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                color:#8C8C8C; 
                font-size:16px;  
                padding-top: 80px;   
                background-size:auto 60px; 
            `
        }else{
            return `
                padding-top: 120px;
                line-height: 28px;
            `
        }
    }};

    
`

const PartnersDiv =styled.div`
    display: grid;
    

    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                grid-template-columns:  repeat(2,1fr);     
                justify-items:center;       
            `
        }else{
            return `
                grid-template-columns: repeat(8,max-content);
                gap:50px;
            `
        }
    }};

   
    justify-content: center;
    align-items: center ;
    >div:first-child{
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 100px;
        letter-spacing: 0.96px;
        text-transform: uppercase;
    }
`

interface PartnerDivParams{
    icon:string;
    w?:string;
    bottom?:string;
}

const PartnerDiv = styled.div<PartnerDivParams>`
    height:42px;
    background-image: url("${(p:PartnerDivParams)=>p.icon}");
    background-position: center;
    background-repeat: no-repeat;
    ${(p:PartnerDivParams)=>{
        if(p.w){
            return `
                width:${p.w};
                min-width:${p.w};
                max-width:${p.w};
            `
        }
        
    }}
    ${(p:PartnerDivParams)=>{
        if(p.bottom){
            return `
                margin-bottom:${p.bottom};
            `
        }
    }}

    cursor: pointer;
   
`

const Prod=(params:{icon:string,text:string,onClick?:(e?:any)=>void})=>{
    
    return <ProdDiv data-interactive={params.onClick!==undefined} onClick={params.onClick} icon={params.icon}>{params.text}</ProdDiv>
}

export const SectionProducts = () =>{

    const openLink=(link:string)=>{
        window.open(link,"_blank");
    }

    return <Section id="products" type="logos">
        <ProdSectionDiv>
            <Prod icon="res/logo_attack_surface_guard.svg" text={Lang.get("qubitText")}/>
            <Prod onClick={()=>{openLink(Lang.get("linkRecovery"))}} icon="res/logo_recovery.svg" text={Lang.get("recoveryText")}/>
            <Prod icon="res/logo_qubit_calc.svg" text={Lang.get("attackSurfaceText")}/>
        </ProdSectionDiv>
        {/*<PartnersDiv>
            <div>{Lang.get("partners")}:</div>
            <PartnerDiv onClick={()=>{openLink(Lang.get("linkBanqueCourtois"))}} w="90px" bottom="20px" icon="res/logo_banque_courtois.png" />
            <PartnerDiv onClick={()=>{openLink(Lang.get("linkStandartChartered"))}} w="107px" bottom="5px" icon="res/logo_standart_chartered.svg" />
            <PartnerDiv onClick={()=>{openLink(Lang.get("linkKB"))}} w="68px" bottom="5px" icon="res/logo_kb.svg" />
            <PartnerDiv onClick={()=>{openLink(Lang.get("linkHangSengBank"))}} w="142px" bottom="10px" icon="res/logo_hang_seng_bank.png" />
            <PartnerDiv onClick={()=>{openLink(Lang.get("linkHanaBank"))}} w="130px" bottom="10px" icon="res/logo_hana_bank.png" />
            <PartnerDiv onClick={()=>{openLink(Lang.get("linkNH"))}} w="100px" icon="res/logo_nh.png" />
            <PartnerDiv onClick={()=>{openLink(Lang.get("linkCIC"))}} w="57px" bottom="9px" icon="res/logo_cic.png" />
        </PartnersDiv>*/}
    </Section>
}