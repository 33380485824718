import styled from "styled-components"
import { Lang } from "./Lang"

const CopyrightDiv = styled.div`
    text-align: center;
    position: absolute;
    //transform: translateY(-30px);
    bottom:30px;
    color:rgba(255,255,255,.5);
    //width: 100%;
    flex-grow: 1;
    width: calc(100% - 60px);
`
export const Copyright = () =>{
    return <CopyrightDiv>{Lang.get("copyright")}</CopyrightDiv>
}