import { ReactElement, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Signal, { SignalHandler } from "../utils/Signal"


interface PanHolderProps{
    maxWidth?:string
}
const PanHolderDiv = styled.div<PanHolderProps>`
    height:100vh;
    width:100%;

    ${({maxWidth})=>{
        if(maxWidth)
            return `
                max-width:${maxWidth};
            `
    }}
    display:flex;
    flex-direction:column;

`

const PanScroller = styled.div`
    flex-grow:1;
    &[data-scroll="true"]{
        overflow:hidden;
    }
    position:relative;
    display:flex;
    flex-direction:column;
    &>div{
        transform:translate3D(0,0,0);
        transition:transform .5s ease 0s;
        flex-grow:1;
        position:absolute;
        width:100%;
        height:100%;
    }
`

const PanSectionDiv = styled.section`
    height:100%;
    overflow:auto;
    display: flex;
    flex-direction: column;
    /*background-color: green;
    outline: 1px solid blue;*/
`

const BulletsDiv = styled.div`
    position: fixed;
    right:25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:100%;
    gap:5px;
    &>div{
        width: 10px;
        height: 10px;
        min-width: 10px;
        min-height: 10px;
        background-color: transparent;
        transition: all .2s  .1s;
        border-radius:50%;
        border:1px solid rgba(0,0,0,.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        filter: invert();
        box-sizing: border-box;
        ::after{
            content: "";
            display: block;
            width: 4px;
            height: 4px;
            transition: all .2s;
            background-color: transparent;
            border-radius: 50%;
        }
    }
    &>div[data-selected="true"]{
        border:1px solid transparent;
        background-color: rgba(0,0,0,.3);
        ::after{
            background-color: transparent;
        }
    }
`

export const S_PAN_SCROLL:Signal<string>=new Signal();
export const S_PAN_SCROLL_COMPLETE:Signal<string>=new Signal();
export const S_PAN_SCROLL_START:Signal<string>=new Signal();
const S_SCROLL_REQUEST:Signal<number>=new Signal();
const S_SCROLL_COMPLETE:Signal<number>=new Signal();


const PanSection = (params:{children:any,id:number})=>{
    
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(()=>{
       
        S_SCROLL_COMPLETE.add((data)=>{

            const onwheel = (e:WheelEvent)=>{
                const el:HTMLElement = e.target as HTMLElement;
                const scrollTop = el.scrollTop; // scroll bar pos
                const scrollHeight = el.scrollHeight; // scrolled to
                const height = Math.round(el.getBoundingClientRect().height); // box size
                
                const scrollOffset = scrollHeight - height
                let canScrollDown = scrollOffset  === 0;
                let canScrollUp = scrollOffset  === 0;
                let diff =scrollTop>0?scrollOffset/scrollTop:-1;
                diff = parseFloat(diff.toFixed(3));
        
                const step = (e as WheelEvent).deltaY>0?1:-1
                let nextSection = params.id + step;
    
                if(!canScrollUp && step === -1){
                    // goes up
                    if(scrollTop === 0)
                        canScrollUp=true;
                }
        
                if(!canScrollDown && step === 1){
                    // goes down
                    if(diff>-1 && diff<1.01)
                        canScrollDown=true;
                }
                
                if(canScrollUp || canScrollDown){
                    S_SCROLL_REQUEST.invoke(nextSection);
                    ref.current?.removeEventListener("wheel",onwheel)
                }
            }

            if(data!==params.id){
                //ref.current!.onwheel=null;
                ref.current?.removeEventListener("wheel",onwheel)
                return;
            }

            ref.current?.addEventListener("wheel",onwheel,{passive:true})

            // Setup touch

            let crd:{
                xDown:number,
                yDown:number
            }|null = null;

            ref.current!.ontouchstart=(evt)=>{
                const firstTouch = evt.touches[0];                                      
                crd = {
                    xDown:firstTouch.clientX,                                      
                    yDown:firstTouch.clientY
                }
                evt.preventDefault();
                evt.stopImmediatePropagation();
                evt.stopPropagation()
            }
    
            ref.current!.ontouchmove=(evt)=>{
                if(!crd)
                    return;
            
                const xUp = evt.touches[0].clientX;                                    
                const yUp = evt.touches[0].clientY;
            
                const xDiff = crd.xDown - xUp;
                const yDiff = crd.yDown - yUp;
                                                                                     
                if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
                    if ( xDiff > 0 ) {
                        // right swipe 
                    } else {
                        // left swipe
                    }                       
                } else {
                    if ( yDiff > 0 ) {
                        // down swipe
                        // TODO: CHECK SCROLL 
                        S_SCROLL_REQUEST.invoke(params.id + 1);
                    } else { 
                        // TODO: CHECK SCROLL 
                        // up swipe 
                        S_SCROLL_REQUEST.invoke(params.id - 1);
                    }                                                                 
                }
                //reset values
                crd=null;
            }

        })

       
        return ()=>{
            ref.current!.ontouchstart = null;
            ref.current!.ontouchmove = null;
            ref.current!.onwheel = null;
        }
        
    },[])
    
    return <PanSectionDiv key={params.id} ref={ref}>{params.children}</PanSectionDiv>
}


let currentSection = 0
let scrolling=false;

interface PanScrollProps{
    sections:{
        section:ReactElement,
        id:string
    }[],
    maxWidth?:string
    useBullets?:boolean
    simpleScroll?:boolean
}

export const PanScroll=({sections,maxWidth,simpleScroll,useBullets}:PanScrollProps)=>{

    const [section,setSection] = useState<{id:number}>({id:currentSection});
    const [nextSection,presetSection] = useState<{id:number}>({id:currentSection});
    const refScrollContainer = useRef<HTMLDivElement|null>(null);

    useEffect(()=>{
        const sh=new SignalHandler();

        sh.add(S_SCROLL_REQUEST,next=>{
            if(next<0)
                next = 0;
            if(next>sections.length-1)
                next = sections.length-1;
            moveTo(next);
        })

        sh.add(S_PAN_SCROLL,name=>{
            for(let i=0;i<sections.length;i++){
                if(sections[i].id === name){
                    S_SCROLL_REQUEST.invoke(i)
                    return;
                }
            }
        })

        window.addEventListener("resize",onResize);
        
        moveTo(section.id)

        return ()=>{
            sh.clear();
            window.removeEventListener("resize",onResize);
        }

    },[])


    const onResize = (e:Event)=>{
        moveTo(section.id)
    }


    const moveTo = (id:number)=>{

        if(scrolling)
            return;
        scrolling=true;

        presetSection({id:id})
 
        S_PAN_SCROLL_START.invoke(sections[id].id)
        const p = refScrollContainer.current!.parentElement!;
        const offset=id * p.getBoundingClientRect().height;
        refScrollContainer.current!.style.transform=`translate3D(0,-${offset}px,0)`

        setTimeout(()=>{
            scrolling=false;
            setSection({id:id});
            S_SCROLL_COMPLETE.invoke(id);
            S_PAN_SCROLL_COMPLETE.invoke(sections[id].id)
        },505)
    }

    let i = 0;
    const content = sections.map(val=>{
        let id=i++;
        return <PanSection key={id} id={id}>
            {val.section}
        </PanSection>
    })
    

    let bullets = null;
    if(useBullets){
        let i=0;
        bullets = <BulletsDiv>{sections.map(val=>{
            let j = i++;
            return <div key={j} data-selected={j === nextSection.id} onClick={()=>{
                S_PAN_SCROLL.invoke(val.id)
            }}></div>
        })}</BulletsDiv>
    }

    // DRAW
    return <PanHolderDiv maxWidth={maxWidth}>
        {bullets}
        <PanScroller data-scroll={!simpleScroll}><div ref={refScrollContainer}>
            {content.map(val=>val)}           
        </div></PanScroller>

    </PanHolderDiv>
}