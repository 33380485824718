import { useEffect } from "react"
import { Lang } from "./Lang"
import { Section } from "./Section"
import { SignalHandler } from "./utils/Signal"
import GD from "./GD"

export const SectionVision=() =>{
    useEffect(()=>{
        const sh = new SignalHandler();
        sh.add(GD.S_LOGO_CLICK,()=>{
            GD.S_MENU_SELECT.invoke("vision");
        })
        return ()=>{
            sh.clear();
        }
    })
    return <Section id={"vision"}>
        <div>{Lang.get("sectionVisionTitle")}</div>
        <div>
            <div>{Lang.get("sectionVisionTxt1")}</div>
            <div>{Lang.get("sectionVisionTxt2")}</div>
            <div>{Lang.get("sectionVisionTxt3")}</div>
        </div>
    </Section>
}