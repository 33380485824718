import { Lang } from "./Lang"
import { Section } from "./Section"

export const SectionCryptography = () =>{
    return <Section id={"cryptography"}>
        <div>
            {Lang.get("sectionCryptographyTitle")}
            <p>{Lang.get("sectionCryptographySubtitle")}</p>
        </div>
        <div>
            <div data-type="block">
                <div>{Lang.get("sectionCryptographyHead1")}</div>
                <div>{Lang.get("sectionCryptographyTxt1")}</div>
            </div>
            <div data-type="block">
                <div>{Lang.get("sectionCryptographyHead2")}</div>
                <div>{Lang.get("sectionCryptographyTxt2")}</div>
            </div>
            <div data-type="block">
                <div>{Lang.get("sectionCryptographyHead3")}</div>
                <div>{Lang.get("sectionCryptographyTxt3")}</div>
            </div>
        </div>
    </Section>
}