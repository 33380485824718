import styled, { keyframes } from "styled-components"
import { Lang } from "./Lang"
import GD from "./GD"
import { Logo } from "./Logo"
import { useEffect, useState } from "react"
import { S_PAN_SCROLL, S_PAN_SCROLL_COMPLETE, S_PAN_SCROLL_START } from "./components/PanScroll"
import { SignalHandler } from "./utils/Signal"

const PanelAnimation = keyframes`
0% {
    transform:translateY(-20px);
    opacity: 0;
}
100% {
    transform:translateY(0);
    opacity: 1;
}
`

const TopbarDiv=styled.div`
    display: grid;
    grid-template-columns: 30px 1fr max-content 30px;
    padding-top: 55px;
    //position: fixed;
    width: 100%;
    max-width: 1280px;
    align-items: center;
    position: fixed;
    top:0;
    z-index: 10;
`

const MenuDiv = styled.div`
    display: flex;
    gap:80px;
    margin-top: -45px;
`
const MenuItemDiv=styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    border-bottom: 2px solid rgba(255,255,255,0);
    transition:border .2s, transform .2s, color .2s;
    :hover{
        border-bottom: 2px solid #FFF;
        transform: scale(1.1,1.1);
        cursor: pointer;
    }

    &[data-selected="true"]{
       // color:#607d8b;
        border-bottom: 2px solid #ffffff;
        pointer-events: none;
    }
`

const MenuIconDiv = styled.div`
    background-image:url("res/menu.png");
    width: 20px;
    height: 10px;
    border-top:3px solid #FFFFFF;
    border-bottom:3px solid #FFFFFF;
    display: flex;
    flex-direction:column;
    justify-content: center;
    cursor: pointer;
    margin-top: -45px;
    ::before{
        content: "";
        display: block;
        height: 3px;
        background-color: #FFFFFF;
    }
`

const MenuFullDiv = styled.div`
    background-color: rgba(0,0,0,.9);
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: column;
    animation: ${PanelAnimation} .2s ease-out;
`
const MenuFullDivBox = styled.div`
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`

const BtnIconClose = styled.div`
    width: 20px;
    height: 20px;
    background-image: url("res/icon_close.svg");
    cursor: pointer;
    align-self: flex-end;
    transform:translate(-40px , 40px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
`

const MenuItem = (params:{data:any,onClick:(e?:any)=>void})=>{
    const [selected,setSelected] = useState<boolean>(false);
    useEffect(()=>{
        const sh:SignalHandler=new SignalHandler();
        sh.add(S_PAN_SCROLL_START,id=>{
            setSelected(params.data.id === id)
        })
        return ()=>{
            sh.clear()
        }
    })

    return <MenuItemDiv data-selected={selected} onClick={params.onClick}>{params.data.text}</MenuItemDiv>
}

export const Topbar = (params:{isMobile:boolean})=>{

    const[menuState,setMenuState]=useState<boolean>(false);

    let menu = [
        {text:Lang.get("vision"),id:"vision"},
        {text:Lang.get("skills"),id:"deepLearning"},
        {text:Lang.get("services"),id:"services"},
        {text:Lang.get("products"),id:"products"},
        {text:Lang.get("contacts"),id:"contacts"}
    ]
    let i = 0;

    const menuClick = ()=>{
        setMenuState(!menuState);
    }

    const mm = menu.map(val=><MenuItem onClick={()=>{
        S_PAN_SCROLL.invoke(val.id);
        if(params.isMobile)
            setMenuState(false)
    }} data={val} key={i++} />)

    if(!params.isMobile)
        return <TopbarDiv>
                <div></div>
                <Logo/>
                <MenuDiv>{mm}</MenuDiv>
                <div></div>
        </TopbarDiv>
    else{
        return <TopbarDiv>
            <div></div>
            <Logo/>
            <MenuIconDiv onClick={menuClick}/>
            <div></div>
            {menuState && <MenuFullDiv>
                <BtnIconClose onClick={menuClick}></BtnIconClose>
                <MenuFullDivBox>
                    {mm}
                </MenuFullDivBox>
            </MenuFullDiv>}
        </TopbarDiv>
    }
}