import { Copyright } from "./Copyright"
import { Lang } from "./Lang"
import { Section } from "./Section"

export const SectionContactUs = ()=>{
    return <Section id={"contacts"}>
         <div>
            {Lang.get("sectionContactUsTitle")}
        </div>
        <div>
            <div>
                {Lang.get("sectionContactUsTxt")}<br/><a href={`mailto:${Lang.get("mail")}`}>{Lang.get("mail")}</a>
            </div>
            <Copyright/>
        </div>
    </Section>
}